import React from "react"
import Layout from "../components/Layout"
import FlourHeartBGImage from "../components/FlourHeartBGImage"
import Mumbles2BGImage from "../components/Mumbles2BGImage"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

export default function () {
  return (
    <Layout>
      <Mumbles2BGImage style={{}}>
        <div className="hero-image-canvas"></div>
      </Mumbles2BGImage>
      <div className="contents-wrapper">
        <div className="text-container">
          <h1>Frequently asked questions</h1>
          <h2>Do I need a referral to attend sessions?</h2>
          <p>
            If you want to claim a Medicare rebate you will need a Mental Health
            Care Plan and a referral letter from your General Practitioner
            before attending your first appointment. (For more information
            please see the <Link to="/fees">fees section</Link>).
          </p>
          <p>
            No referral letter is needed if you intend to pay privately or via
            your private health insurance.
          </p>

          <h2>How do I make an initial appointment?</h2>

          <p>
            To make an initial appointment just <Link to="/contact"> contact Sarah</Link> by email or phone.
          </p>
          <p>
            She will provide some brief information about the service and will
            be able to book you in for a time that suits you.
          </p>

          <h2>What should I expect from an initial appointment?</h2>

          <p>
            When you arrive for your session please take a seat in the waiting
            room. Please note there is no receptionist on duty, so Sarah will
            come and greet you and take you to the consulting room.
          </p>

          <p>
            For an initial consultation please arrive 10 minutes early as there
            might be some forms to complete before the session starts.
          </p>
          <p>
            The initial session is used to explore what current difficulties you
            are experiencing, the history of those difficulties, some of your
            personal and family history, and your goals and expectations of
            therapy. You will be able to ask any questions you have during this
            session.
          </p>

          <p>
            Towards the end of the session Sarah will discuss with you potential
            treatment options and what to expect moving forward in therapy.
            Should it become apparent that your difficulties are outside the
            scope of Sarah’s expertise she will be able to provide details of
            alternative specialist services.
          </p>

          <h2>Do you offer online sessions?</h2>

          <p>
            Yes! I offer online sessions via Zoom. All you need is a computer
            with an internet connection, a private space so that your session
            remains confidential and you are not disrupted, and a Zoom username
            (this is free).
          </p>

          <p>
            Should you wish to do online sessions please <Link to="/contact">contact Sarah</Link> and she
            can provide more information.
          </p>

          <h2>How many sessions will I need?</h2>

          <p>
            This depends on your current situation and the difficulties you are
            experiencing. Clients are often able to achieve a significant amount
            of change within six to 12 sessions.
          </p>

          <p>
            For Couples Therapy the average number of sessions is between 12 and
            20, however this might be more if there is a background of trauma.
          </p>
          <p>
            If difficulties are long standing and are having a significant
            impact on your functioning, therapy might be a long term commitment.
            This will be discussed with you in your initial appointment.
          </p>

          <h2>How can I make the most out of therapy?</h2>

          <p>
            It is important to make a personal commitment to the therapy
            process. Clients who attend on a regular basis (either weekly or
            fortnightly) are able to make the best gains.
          </p>
          <p>
            Clients are encouraged to do work between sessions - this might
            range from observing their own patterns of thought or behaviour, to
            practising skills learnt in session or trying out new things.
          </p>

          <h2>Is Sarah the right therapist for me?</h2>

          <p>
            The therapeutic relationship and the alliance formed between the
            client and the therapist has proven to be a key factor in the
            success of therapy. Sarah works hard to understand the individual needs of each of her clients.
          </p>
        </div>
      </div>
    </Layout>
  )
}
